<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">大屏管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">大屏设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                clearable
                placeholder="请输入机构名称"
              />
              <!-- <el-select
                clearable
                v-model="compId"
                filterable
                remote
                :remote-method="searchCopmp"
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  size="small"
                  v-for="item in compList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select> -->
            </div>
            <div title="联系电话" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">联系电话:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                clearable
                placeholder="请输入联系电话"
              />
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="联系电话"
                align="left"
                prop="compPhone"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleSet(scope.row)"
                    >大屏设置</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "lgScreenSetList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      compName: "",
      mobile: "",
      compList: [],
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
    this.getTraintype();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.mobile) {
        params.compPhone = this.mobile;
      }
      this.doFetch({
        url: "/sys/company/page-list",
        params,
        pageNum,
      });
    },
    /**
     * 单位
     */
    searchCopmp(query) {
      if (query.trim().length > 2) {
        this.$post("/sys/company/likeCompName", { compName: query }).then(
          (res) => {
            this.compList = res.data;
          }
        );
      } else {
        this.compList = [];
      }
    },
    /**
     * 修改状态
     */
    handleSet(row) {
      this.$router.push({
        path: "/web/lgScreenSet",
        query: {
          compName:row.compName,
          compId:row.compId
        },
      });
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
</style>
